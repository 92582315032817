import { instance,instance1 } from "../index.js"

export function postAccount(params) {
    return instance.post("PufaAccount/postAccount", params)
}

export function province(){
    return instance1.get("https://api.cqlm-tech.cn/pfyh/province")
}

export function city(){
    return instance1.get("https://api.cqlm-tech.cn/pfyh/city")
}