import axios from "axios"

const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})
const instance1 = axios.create({
    baseURL: "",
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})
instance.interceptors.request.use(config => {
    return config
}, err => {
    return Promise.reject(err)
})
instance.interceptors.response.use(data => {
    return data
}, err => {
    return err
})

instance1.interceptors.request.use(config => {
    return config
}, err => {
    return Promise.reject(err)
})

instance1.interceptors.response.use(data => {
    return data
}, err => {
    return err
})

export { instance, instance1 }