<template>
  <div class="out-wrap">
    <div class="head">
      <div @click="goBack">
        <i class="el-icon-arrow-left"></i><span class="back-text">返回</span>
      </div>
    </div>

    <el-scrollbar :style="{ height: '100%' }">
      <div class="wrap">
        <el-form ref="form" :model="form" :rules="rules">
          <el-form-item label="预约手机号：" prop="defaultMobile">
            <el-input
              v-model.trim="form.defaultMobile"
              maxlength="11"
            ></el-input>
          </el-form-item>
          <el-form-item label="客户名称：" prop="clientName">
            <el-input v-model.trim="form.clientName" maxlength="200"></el-input>
          </el-form-item>
          <el-form-item label="账户性质类型代码：" prop="acctTp">
            <el-select v-model.trim="form.acctTp" @change="isShowType">
              <el-option
                v-for="item in acctTpOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-show="isShow"
            label="一般存款账户类型："
            prop="depositAcctType"
          >
            <el-select v-model.trim="depositAcctType">
              <el-option
                v-for="item in depositAcctTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="详细办公地址：" prop="officeAdr">
            <el-input v-model.trim="form.officeAdr"></el-input>
          </el-form-item>
          <el-form-item label="统一社会信用代码到期日：" prop="crCtfExpDt" :rules="!isDisabledValidity1 ? rules.crCtfExpDt : [{ required: false }]">
                <el-select v-model="validityDate1" @change="checkValidityDate(1)" class="validity_sel">
                    <el-option v-for="item in validityDateOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            <el-date-picker
              v-model.trim="form.crCtfExpDt"
              :editable="false"
              type="date"
              :placeholder="isDisabledValidity1 ? '长期有效' : '选择日期'"
              format="yyyy 年 MM月 dd日"
              :picker-options="pickerOptions1"
              :disabled="isDisabledValidity1"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item prop="rgstCptl">
            <template slot="label">注册资金（元）：<span class="rgst_tips">如无注册资金则输入0</span></template>
            <el-input v-model.trim="form.rgstCptl" maxlength="13"></el-input>
          </el-form-item>
          <el-form-item
            v-if="form.apntmChannel == '5'"
            label="经营范围："
            prop="bussScope1"
            :rules="
              form.apntmChannel == '5'
                ? rules.bussScope1
                : [{ required: false }]
            "
          >
            <el-input v-model.trim="form.bussScope1"></el-input>
          </el-form-item>
          <el-form-item
            v-if="form.apntmChannel == '5'"
            label="注册地址："
            prop="aprvAdr"
            :rules="
              form.apntmChannel == '5' ? rules.aprvAdr : [{ required: false }]
            "
          >
            <el-input v-model.trim="form.aprvAdr"></el-input>
          </el-form-item>
          <el-form-item
            v-if="form.acctTp == 'D'"
            label="基本存款账户开户许可证核准号："
            prop="openAcctLcnsId"
            :rules="
              form.acctTp == 'D' ? rules.openAcctLcnsId : rules.openAcctLcnsIdNR
            "
          >
            <!-- 一般存款账户必输 -->
            <el-input
              v-model.trim="form.openAcctLcnsId"
              maxlength="16"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="form.acctTp == 'D'"
            label="基本户开户银行："
            prop="openBrName"
            :rules="
              form.acctTp == 'D' ? rules.openBrName : [{ required: false }]
            "
          >
            <el-input v-model.trim="form.openBrName"></el-input>
          </el-form-item>
          <el-form-item
            v-if="form.acctTp == 'D'"
            label="基本户账号："
            prop="bscBnkAcctNo"
            :rules="
              form.acctTp == 'D' ? rules.bscBnkAcctNo : rules.bscBnkAcctNoNR
            "
          >
            <el-input
              v-model.trim="form.bscBnkAcctNo"
              maxlength="32"
            ></el-input>
          </el-form-item>
          <el-form-item label="法定代表人姓名：" prop="corpName">
            <el-input v-model.trim="form.corpName" maxlength="22"></el-input>
          </el-form-item>
          <el-form-item label="法定代表人证件种类：" prop="lglRprsntCtfType">
            <el-select @change="checkType" v-model.trim="form.lglRprsntCtfType">
              <el-option
                v-for="item in ctfTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="法定代表人身份证件号码：" prop="lglRprsntCtfNo">
            <el-input
              v-model.trim="form.lglRprsntCtfNo"
              maxlength="20"
              @input="inputNo"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="法定代表人身份证件到期日："
            prop="corpCtfExpDate"
            :rules="!isDisabledValidity2 ? rules.corpCtfExpDate : [{ required: false }]"
          >
          <el-select :disabled="isLang" v-model="validityDate2" @change="checkValidityDate(2)" class="validity_sel">
              <el-option v-for="item in validityDateOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
            <el-date-picker
              v-model.trim="form.corpCtfExpDate"
              :editable="false"
              type="date"
             :placeholder="isDisabledValidity2 ? '长期有效' : '选择日期'"
              format="yyyy 年 MM月 dd日"
              :picker-options="pickerOptions2"
              :disabled="isDisabledValidity2"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="法定代表人手机：" prop="gntCorpTelephone">
            <el-input
              v-model.trim="form.gntCorpTelephone"
              maxlength="11"
            ></el-input>
          </el-form-item>
          <el-form-item label="统一社会信用代码号码：" prop="unfSocCrdtNo">
            <el-input
              v-model.trim="form.unfSocCrdtNo"
              maxlength="18"
            ></el-input>
          </el-form-item>
          <el-form-item v-show="isOriginHei" class="bottom-btn">
            <el-button
              type="primary"
              @click="doCreate"
              :disabled="isDisabled"
              :loading="btnLoding"
              >立即创建</el-button
            >
            <el-button @click="resetForm">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-scrollbar>
    <el-dialog
      :append-to-body="true"
      :visible.sync="dialogVisible"
      width="270px"
      :before-close="handleClose"
    >
      <p class="dialog_title">申请成功</p>
      <p class="dialog_top">请留意法人代表/经办人手机短信</p>
      <p class="dialog_bottom">流水号可用于查询进度</p>
      <el-button class="dialog_btn" @click="handleClose">知道了</el-button>
    </el-dialog>
    <el-dialog
      :append-to-body="true"
      :visible.sync="resetDialog"
      width="270px"
      :before-close="cancelReset"
    >
      <p class="dialog_re_top">是否确认重置</p>
      <p class="dialog_bottom">将清空所有已填信息</p>
      <el-button class="dialog_reset_btn" @click="doReset">确认</el-button>
      <el-button class="dialog_reset_btn2" @click="cancelReset">取消</el-button>
    </el-dialog>
  </div>
</template>

<script>
import mixins from "./mixins";
import { postAccount, province, city } from "../../api/form/form.js";
export default {
  mixins: [mixins],
  data() {
    return {
      form: {
        apntmChannel: '6',
      },
      submitForm: {},
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7
        },
      },
      pickerOptions2: {
        disabledDate(time) {
            let nowYear = new Date().getFullYear();
            return time.getTime() < Date.now() - 8.64e7 || time.getFullYear() > nowYear + 20
        },
      },
      isShow: false,
      typeFlag: true,
      isRequired: false,
      documentHeight: document.documentElement.clientHeight, // 默认高度
      isOriginHei: true, // 这个属性是固定定位按钮的显隐开关
      stopClickCreate: false,
      isDisabled: false, // 按钮是否禁用
      btnLoding: false,
      resetDialog: false,
      dialogVisible: false,
      cityDataAll: [],
      cityData200: [],
      validityDate1: '长期有效期',
      validityDate2: '固定有效期',
      validityDateOptions: [
          {label: '长期有效期', value: '长期有效期'},
          {label: '固定有效期', value: '固定有效期'}
      ], // 有效期
      isDisabledValidity1: true, // 长期有效期启动
      isDisabledValidity2: false, // 长期有效期启动
      isLang: false, // 是否年龄大于46
    };
  },
  created() {
    // this.getProvince();
  },
  mounted() {
    window.onresize = () => {
      if (document.activeElement.tagName == "INPUT") {
        window.setTimeout(() => {
          document.activeElement.scrollIntoViewIfNeeded();
        }, 100);
      }
      return (() => {
        if (this.documentHeight > document.documentElement.clientHeight) {
          this.isOriginHei = false;
        } else {
          this.isOriginHei = true;
        }
      })();
    };
  },
  methods: {
    // 获取城市列表
    async getProvince() {
      let req1 = province();
      let req2 = city();
      await Promise.all([req1, req2]).then((res) => {
        res[0].data.data.forEach((item) => {
          this.coAdrOptions.push({
            label: item.name,
            value: item.code,
          });
        });
        this.cityDataAll = res[1].data.data
        this.cityData200 = res[1].data.data.slice(0, 200)
      });
    },

    filterHospital(query=""){
        let arr = this.cityDataAll.filter(item=>{
            return item.areaName.includes(query)
        })
        if(arr.length > 200){
            this.cityData200 = arr.slice(0,200)
        }else{
            this.cityData200 = arr
        }
    },
    // 统一社会信用代码有效期
    checkValidityDate(num){
        if(num == 1){
            this.isDisabledValidity1 = this.validityDate1=="长期有效期" ? true : false
            this.$set(this.form, "crCtfExpDt", "")
        }else{
            this.isDisabledValidity2 = this.validityDate2=="长期有效期" ? true : false
            this.$set(this.form, "corpCtfExpDate", "")
        }
    },
    //   是否展示一般存款账户类型选择
    isShowType(value) {
      this.isShow = false;
      if (value == "D") this.isShow = true;
    },
    // 是否加上必填规则
    isRFn(v) {
      if (v.length < 1) {
        this.isRequired = false;
      } else {
        this.isRequired = true;
      }
    },
    checkType(val) {
        this.validityDate2 = "固定有效期"
        this.isDisabledValidity2 = false
        if(val != 1 && this.isLang){
            this.isLang = false
        }else if(this.form.lglRprsntCtfNo && this.form.lglRprsntCtfNo.length > 0){
            if(val == 1){
                this.inputNo(this.form.lglRprsntCtfNo)
            }
        }
    },
    // 身份证号，判断是否年龄大于等于46
    inputNo(val){
        if(this.form.lglRprsntCtfType && this.form.lglRprsntCtfType == 1) {
            let age = 0,yearBirth,monthBirth,dayBirth;
            if(val.length == 15){
                let org_birthday = val.substring(6, 12);
                //获取出生年月日
				yearBirth = "19" + org_birthday.substring(0, 2);
				monthBirth = org_birthday.substring(2, 4);
				dayBirth = org_birthday.substring(4, 6);
            }else if (val.length == 18) {
				//获取出生年月日
				yearBirth = val.substring(6,10);
				monthBirth = val.substring(10,12);
				dayBirth = val.substring(12,14);
			}
            //获取当前年月日并计算年龄
			let myDate = new Date();
			let monthNow = myDate.getMonth() + 1;
			let dayNow = myDate.getDate();
			age = myDate.getFullYear() - yearBirth;
			if(monthNow < monthBirth || (monthNow == monthBirth && dayNow < dayBirth)){
				age--;
			}
            if(age >= 46){
                /* this.validityDate2 = "长期有效期"
                this.isDisabledValidity2 = true */
                this.isLang = false
                this.$set(this.form, "corpCtfExpDate", "")
            }else{
                this.isLang = true
                this.validityDate2 = "固定有效期"
                this.isDisabledValidity2 = false
            }
        }else{
            this.isLang = false
        }
    },
    // 重置
    doReset(){
      this.resetDialog = false
      this.reset()
    },
    reset(){
      this.$refs.form.resetFields();
      this.form = {};
      this.isShow = false;
      this.typeFlag = true;
      this.isRequired = false;
      this.validityDate2 = "固定有效期"
      this.isDisabledValidity2 = false
      this.coAdrObj = { coAdrProvCityCode: "", coAdr: "" };
    },
    cancelReset(){
      this.resetDialog = false
    },
    resetForm() {
      this.resetDialog = true
    },
    formatTime(time) {
      let y = time.getFullYear();
      let m = time.getMonth() + 1;
      let d = time.getDate();
      let a = [y, m, d]
        .map((item) => {
          if (item < 10) {
            return "0" + item;
          } else {
            return item;
          }
        })
        .join("");
      return a;
    },
    formatTimeI(time) {
      let h1 = time[0].getHours();
      let h2 = time[1].getHours();
      let m1 = time[0].getMinutes();
      let m2 = time[1].getMinutes();
      if (h1 < 10) h1 = `0${h1}`;
      if (h2 < 10) h2 = `0${h2}`;
      if (m1 < 10) m1 = `0${m1}`;
      if (m2 < 10) m2 = `0${m2}`;
      return `${h1}${m1}-${h2}${m2}`;
    },
    cekData() {
      this.submitForm = { ...this.form };
      this.submitForm.managerNo = "憨牛科技"
      this.submitForm.instEghName = "重庆憨牛科技有限公司"
      if (this.form.acctTp == "D") {
        this.submitForm.depositAcctType = this.depositAcctType;
      } else {
        delete this.submitForm.depositAcctType;
      }
      if (this.coAdrObj.coAdrProvCityCode)
        this.submitForm.coAdrProvCityCode = this.coAdrObj.coAdrProvCityCode;
      if (this.coAdrObj.coAdr) this.submitForm.coAdr = this.coAdrObj.coAdr;

      if (this.form.openTmStmp)
        this.submitForm.openTmStmp = this.formatTimeI(this.form.openTmStmp);
      if(this.validityDate2 == "长期有效期"){
            this.submitForm.corpCtfExpDate = this.formatTime(new Date("9999-12-31"))
        }else if(this.form.corpCtfExpDate){
            this.submitForm.corpCtfExpDate = this.formatTime(this.form.corpCtfExpDate);
      }
      if (this.form.openAcctDate)
        this.submitForm.openAcctDate = this.formatTime(this.form.openAcctDate);
        if(this.validityDate1 == "长期有效期"){
            this.submitForm.crCtfExpDt = this.formatTime(new Date("9999-12-31"))
        }else if(this.form.crCtfExpDt){
            this.submitForm.crCtfExpDt = this.formatTime(this.form.crCtfExpDt);
        }
      if (this.form.lglRprsntCtfExpDate)
        this.submitForm.lglRprsntCtfExpDate = this.formatTime(
          this.form.lglRprsntCtfExpDate
        );
      if (this.form.agentCtfExpDate)
        this.submitForm.agentCtfExpDate = this.formatTime(
          this.form.agentCtfExpDate
        );
      if (this.form.ctfVldDate)
        this.submitForm.ctfVldDate = this.formatTime(this.form.ctfVldDate);
      if (this.form.lglRprsntCtfExpDate)
        this.submitForm.lglRprsntCtfExpDate = this.formatTime(
          this.form.lglRprsntCtfExpDate
        );
      if (this.form.ctfExpDate)
        this.submitForm.ctfExpDate = this.formatTime(this.form.ctfExpDate);
      if (this.form.ctfExpDate3)
        this.submitForm.ctfExpDate3 = this.formatTime(this.form.ctfExpDate3);
      this.form.officeTel
        ? (this.submitForm.officeTel = this.submitForm.officeTel.replace(
            "-",
            ""
          ))
        : "";
      this.form.fixedTel
        ? (this.submitForm.fixedTel = this.submitForm.fixedTel.replace("-", ""))
        : "";
      this.form.fixedTel
        ? (this.submitForm.fixedTel = this.submitForm.fixedTel.replace("-", ""))
        : "";
      this.form.fixedTel2
        ? (this.submitForm.fixedTel2 = this.submitForm.fixedTel2.replace(
            "-",
            ""
          ))
        : "";
      this.form.agntTelNo
        ? (this.submitForm.agntTelNo = this.submitForm.agntTelNo.replace(
            "-",
            ""
          ))
        : "";
    },
    // 创建
    doCreate() {
      if (this.stopClickCreate) {
        return false;
      }
      this.stopClickCreate = true;
      setTimeout(() => {
        this.stopClickCreate = false;
      }, 1000);
      this.cekData();
      this.$refs.form.validate((valid) => {
        if (!valid) {
          this.$message({
            type: "error",
            customClass: "show-msg",
            message: "所填内容格式不正确！",
          });
          this.btnLoding = false;
        } else {
          this.btnLoding = true;
          this.isDisabled = true;
          postAccount(this.submitForm).then((res) => {
            if (res.status == 200) {
              this.btnLoding = false;
              if (res.data.statusCode == "0000") {
                this.dialogVisible = true;
                this.reset();
                this.isDisabled = false;
              } else if (res.data.statusCode == "0002") {
                this.$message({
                  type: "error",
                  customClass: "show-msg",
                  message: res.data.statusMsg,
                });
                this.isDisabled = false;
              } else {
                this.$message({
                  type: "warning",
                  customClass: "show-msg",
                  message: res.data.statusMsg,
                });
                this.isDisabled = false;
              }
            } else {
              this.$message({
                type: "error",
                customClass: "show-msg",
                message: "服务器内部异常！",
              });
              this.isDisabled = false;
              this.btnLoding = false;
            }
          });
        }
      });
    },
    handleClose() {
      this.$router.push("/");
    },
    // 返回
    goBack() {
      this.$router.push("/");
    },
  },
};
</script>
<style lang="less">
.el-form-item__label {
  text-align: left;
  line-height: 20px;
  padding-bottom: 0.1rem;
}
.el-main {
  padding: 0;
}
.el-select {
  width: 100%;
  margin-right: 10px;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
.el-icon-arrow-left:before {
  position: relative;
  top: -1px;
}
.el-dialog {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 200px;
  text-align: center;
  border-radius: 10px;
}
.rgst_tips{
  color: rgb(155, 155, 155);
  font-size: 12px;
}
</style>
<style lang="less" scoped>
/deep/.el-dialog__body .dialog_title {
  position: relative;
  top: -24px;
  font-size: 17px;
  font-weight: bold;
  color: #000;
}
/deep/.el-dialog__body .dialog_top {
  position: relative;
  top: -10px;
  font-size: 15px;
  margin-bottom: 15px;
  color: #000;
}
/deep/.el-dialog .dialog_bottom {
  position: relative;
  top: -10px;
  font-size: 11px;
  margin-bottom: 20px;
}
/deep/.el-dialog__body .dialog_btn {
  position: relative;
  top: -10px;
  width: 193px;
  height: 44px;
  font-size: 15px;
  color: #fff;
  border-radius: 25px;
  background: linear-gradient(90deg, #63b1ff, #6581fc);
}
/deep/.el-dialog__body .dialog_re_top{
  position: relative;
  top: -10px;
  font-size: 16px;
  margin-bottom: 15px;
  color: #000;
}
/deep/.el-dialog__body .dialog_reset_btn{
  width: 37%;
  color: #fff;
  background: linear-gradient(90deg, #63b1ff, #6581fc);
}
/deep/.el-dialog__body .dialog_reset_btn2{
  width: 37%;
}
.head {
  width: 100%;
  height: 1.2rem;
  position: fixed;
  background-color: #fff;
  box-shadow: 0px 4px 18px -12px #000;
  max-width: 480px;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  align-items: center;
  padding-left: 10px;
  z-index: 3;
  font-size: 17px;
  letter-spacing: 2px;
  .el-icon-arrow-left {
    padding-right: 0.15rem;
    font-size: 24px;
    vertical-align: middle;
    line-height: 0;
  }
  .back-text {
    position: relative;
    left: -10px;
  }
  div {
    box-sizing: border-box;
    padding: 17px 0px;
    cursor: pointer;
  }
}
/deep/ .el-scrollbar__wrap {
  overflow-x: hidden;
}
.out-wrap {
  position: relative;
  max-width: 480px;
  height: 100vh;
  margin: 0 auto;
  background-color: #fff;
  overflow-y: hidden;
  .wrap {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 1.8rem 10px 80px 10px;
    box-sizing: border-box;

    /deep/ .el-form-item__label {
      font-size: 15px;
    }
    /deep/ .el-date-editor {
      max-width: 100%;
      .el-range-input:first-of-type {
        width: 17%;
        margin-right: 20%;
      }
    }
    /deep/ .el-date-editor .el-range-separator {
      width: 10%;
    }
    .address {
      /deep/.el-form-item__content {
        display: flex;
        width: 100%;
      }
      /deep/.el-form-item__content > .el-input {
        flex: 1;
      }
      /deep/ .el-select {
        flex: 1;
      }
    }
    .validity_sel{
        margin-bottom: 10px;
    }
    .bottom-btn {
      position: fixed;
      display: flex;
      bottom: 0 !important;
      margin-bottom: 0 !important;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      max-width: 460px;
      height: 75px;
      bottom: 0px;
      left: 50%;
      transform: translate(-50%);
      background: #fff;
      /deep/ .el-form-item__content {
        text-align: center;
      }
    }
    /deep/ .el-button {
      width: 3.8rem;
      margin: 0 10px;
      font-size: 15px;
    }
  }
}
</style>>