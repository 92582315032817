export default {
    data() {
        // 手机号正则
        const mobileRegex = /^(0|86|17951)?(13[0-9]|15[012356789]|16[567]|17[0-8]|18[0-9]|19[0-9]|14[5679])[0-9]{8}$/
        // 固定电话正则
        const fixedTelRegex = /^([0-9]{3,4}-)?[0-9]{4,8}$/
        // 身份证号正则
        const prsnIdCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
        // 护照正则
        const passportRegex = /^[0-9a-zA-Z]*$/
        // 军官证正则
        const officerRegex = /^[\u4e00-\u9fa5]{1,}(字第)([0-9a-zA-Z]{4,8})(号?)$/
        // 港澳居民来往内地通行证正则
        const hkmRegex = /[HMhm]{1}([0-9]{10}|[0-9]{8})$/
        // 户口簿正则
        const hukouRegex = /^\d{9}$/
        // 台湾同胞来往内地通行证正则
        const twRegex = /^\d{8}$|^[a-zA-Z0-9]{10}$|^\d{18}$/
        // 外国人居留证正则
        const arRegex = /^[A-Z]{3}\d{6}(?:0[1-9]|1[021])(?:0[1-9]|[21]\d|3[10])\d{2}$/
        // 邮箱正则
        const emailRegex = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
        // 核准号
        let approvalRegex = /^[0-9a-zA-Z]*$/

        // 各个证件验证
        function regexFn(num, value, callback) {
            switch (num) {
                case '1':
                    if (value && !prsnIdCard.test(value)) {
                        callback(new Error("证件格式不正确！"))
                    } else {
                        callback()
                    };
                    break;
                case '2':
                    if (value && !passportRegex.test(value)) {
                        callback(new Error("证件格式不正确！"))
                    } else {
                        callback()
                    };
                    break;
                case '3':
                    if (value && !officerRegex.test(value)) {
                        callback(new Error("证件格式不正确！"))
                    } else {
                        callback()
                    };
                    break;
                case '5':
                    if (value && !hkmRegex.test(value)) {
                        callback(new Error("证件格式不正确！"))
                    } else {
                        callback()
                    };
                    break;
                case '6':
                    if (value && !hukouRegex.test(value)) {
                        callback(new Error("证件格式不正确！"))
                    } else {
                        callback()
                    };
                    break;
                case 'A':
                    if (value && !officerRegex.test(value)) {
                        callback(new Error("证件格式不正确！"))
                    } else {
                        callback()
                    };
                    break;
                case 'B':
                    if (value && !twRegex.test(value)) {
                        callback(new Error("证件格式不正确！"))
                    } else {
                        callback()
                    };
                    break;
                case 'D':
                    if (value && !arRegex.test(value)) {
                        callback(new Error("证件格式不正确！"))
                    } else {
                        callback()
                    };
                    break;
                default: if (value && value.length > 20) {
                    callback(new Error("证件格式不正确！"))
                } else {
                    callback()
                };
            }
        }

        // 手机号验证
        const defaultMobileValidate = (rule, value, callback) => {
            if (!mobileRegex.test(value)) {
                callback(new Error("手机号码格式不正确！"))
            } else {
                callback()
            }
        }
        // 营销代码
        const managerNoValidate = (rule, value, callback) => {
            if (value && value.length > 8) {
                callback(new Error("营销代码长度不正确！"))
            } else {
                callback()
            }
        }
        // 办公电话
        const officeTelValidate = (rule, value, callback) => {
            if (value) {
                if (!mobileRegex.test(value) && !fixedTelRegex.test(value)) {
                    callback(new Error("办公电话码格式不正确！"))
                } else {
                    callback()
                }
            } else {
                callback()
            }
        }

        // 邮政编码
        const zipCodeValidate = (rule, value, callback) => {
            const zipCodeRegex = /^[1-9][0-9]{5}$/
            if (value) {
                if (!zipCodeRegex.test(value)) {
                    callback(new Error("邮政编码格式不正确！"))
                } else {
                    callback()
                }
            } else {
                callback()
            }
        }
        // 注册资金
        const rgstCptlValidate = (rule, value, callback) => {
            const rgstCptlRegex = /^[0-9][0-9]*([.][0-9]{1,2})?$/
            if (!rgstCptlRegex.test(value)) {
                callback(new Error("注册资金格式不正确！"))
            } else {
                callback()
            }
        }
        // 基本存款账户开户许可证核准号
        const openAcctLcnsIdValidate = (rule, value, callback) => {
            if (value && !approvalRegex.test(value)) {
                callback(new Error("基本存款账户开户许可证核准号格式不正确！"))
            } else {
                callback()
            }
        }
        // 基本户账号
        const bscBnkAcctNoValidate = (rule, value, callback) => {
            let numRegex = /^\d{1,}$/
            if (value && !numRegex.test(value)) {
                callback(new Error("基本户账号格式不正确！"))
            } else {
                callback()
            }
        }
        // 财务负责人身份证件号码
        const prsnIdCardIdValidate = (rule, value, callback) => {
            if (!this.form.ctfType && value) {
                callback(new Error("请先选择身份证件类型！"))
            } else {
                regexFn(this.form.ctfType, value, callback)
            }

        }
        // 财务负责人固定电话
        const fixedTelValidate = (rule, value, callback) => {
            if (value) {
                if (!fixedTelRegex.test(value)) {
                    callback(new Error("固定电话格式不正确！"))
                } else {
                    callback()
                }
            } else {
                callback()
            }
        }
        // 财务负责人手机
        const mblNoValidate = (rule, value, callback) => {
            if (value && !mobileRegex.test(value)) {
                callback(new Error("手机号码格式不正确！"))
            } else {
                callback()
            }
        }
        // 法定代表人身份证件号码
        const lglRprsntCtfNoValidate = (rule, value, callback) => {
            if (!this.form.lglRprsntCtfType && value) {
                callback(new Error("请先选择身份证件类型！"))
            } else {
                regexFn(this.form.lglRprsntCtfType, value, callback)
            }
        }
        // 法定代表人固定电话
        const fixedTel2Validate = (rule, value, callback) => {
            if (value && !fixedTelRegex.test(value)) {
                callback(new Error("固定电话格式不正确！"))
            } else {
                callback()
            }
        }
        // 法定代表人手机
        const gntCorpTelephoneValidate = (rule, value, callback) => {
            if (value && !mobileRegex.test(value)) {
                callback(new Error("手机号码格式不正确！"))
            } else {
                callback()
            }
        }
        // 授权代理人身份证件号码
        const agentCtfIdValidate = (rule, value, callback) => {
            if (!this.form.idType && value) {
                callback(new Error("请先选择身份证件类型！"))
            } else {
                regexFn(this.form.idType, value, callback)
            }
        }
        // 授权代理人固定电话
        const agntTelNoValidate = (rule, value, callback) => {
            if (value && !fixedTelRegex.test(value)) {
                callback(new Error("固定电话格式不正确！"))
            } else {
                callback()
            }
        }
        // 授权代理人手机
        const agntPhoneNoValidate = (rule, value, callback) => {
            if (value && !mobileRegex.test(value)) {
                callback(new Error("手机号码格式不正确！"))
            } else {
                callback()
            }
        }
        // 控股股东或实际控制人证件号码
        const shrhlrCtfIdValidate = (rule, value, callback) => {
            if (!this.form.shrhlrCtfType && value) {
                callback(new Error("请先选择身份证件类型！"))
            } else {
                regexFn(this.form.shrhlrCtfType, value, callback)
            }
        }
        // 上级法人或主管单位基本存款账户开户许可证核准号
        const openAcctLcnsId1Validate = (rule, value, callback) => {
            if (value && !approvalRegex.test(value)) {
                callback(new Error("核准号格式不正确！"))
            } else {
                callback()
            }
        }
        // 上级法人或主管单位基本存款账户组织机构代码
        const orgInstIdValidate = (rule, value, callback) => {
            if (value && value.length > 20) {
                callback(new Error("组织机构代码格式不正确！"))
            } else {
                callback()
            }
        }
        // 上级法人或主管单位法定代表人证件号码
        const corpCtfIdValidate = (rule, value, callback) => {
            if (!this.form.corpCtfType && value) {
                callback(new Error("请先选择身份证件类型！"))
            } else {
                regexFn(this.form.corpCtfType, value, callback)
            }
        }
        // 支付联系人1
        const payerCtfIDValidate = (rule, value, callback) => {
            if (!this.form.ctfType2 && value) {
                callback(new Error("请先选择身份证件类型！"))
            } else {
                regexFn(this.form.ctfType2, value, callback)
            }
        }
        // 支付联系人1电话
        const contactTelValidate = (rule, value, callback) => {
            if (value && !mobileRegex.test(value)) {
                callback(new Error("手机号码格式不正确！"))
            } else {
                callback()
            }
        }
        // 支付联系人1邮箱
        const ctcEmailValidate = (rule, value, callback) => {
            if (value && !emailRegex.test(value)) {
                callback(new Error("邮箱格式不正确！"))
            } else {
                callback()
            }
        }
        // 支付联系人2
        const payerCtfID1Validate = (rule, value, callback) => {
            if (!this.form.ctfType3 && value) {
                callback(new Error("请先选择身份证件类型！"))
            } else {
                regexFn(this.form.ctfType3, value, callback)
            }
        }
        // 支付联系人2电话
        const contactTel2Validate = (rule, value, callback) => {
            if (value && !mobileRegex.test(value)) {
                callback(new Error("手机号码格式不正确！"))
            } else {
                callback()
            }
        }
        // 支付联系人2邮箱
        const ctcEmail1Validate = (rule, value, callback) => {
            if (value && !emailRegex.test(value)) {
                callback(new Error("邮箱格式不正确！"))
            } else {
                callback()
            }
        }

        // 统一社会信用代码号码
        const unfSocCrdtNoValidate = (rule, value, callback) => {
            if (value && !approvalRegex.test(value)) {
                callback(new Error("统一社会信用代码号码格式不正确！"))
            } else {
                callback()
            }
        }

        return {
            depositAcctType: "1",
            acctTpOptions: [
                {
                    label: "C：基本存款账户",
                    value: "C",
                },
                {
                    label: "D：一般存款账户",
                    value: "D",
                },
            ],
            depositAcctTypeOptions: [
                {
                    label: "1-借款需要",
                    value: '1',
                },
                {
                    label: "2-其他结算需要",
                    value: '2',
                },
            ],
            ctfTypeOptions: [
                {
                    label: "1-身份证",
                    value: '1',
                },
                {
                    label: "2-护照",
                    value: '2',
                },
                {
                    label: "3-军官证",
                    value: '3',
                },
                {
                    label: "4-武警证",
                    value: '4',
                },
                {
                    label: "5-港澳居民来往内地通行证",
                    value: '5',
                },
                {
                    label: "6-户口簿",
                    value: '6',
                },
                {
                    label: "7-其他",
                    value: '7',
                },
                {
                    label: "8-警官证",
                    value: '8',
                },
                {
                    label: "9-执行公务证",
                    value: '9',
                },
                {
                    label: "A-士兵证",
                    value: "A",
                },
                {
                    label: "B-台湾同胞来往内地通行证",
                    value: "B",
                },
                {
                    label: "C-临时身份证",
                    value: "C",
                },
                {
                    label: "D-外国人居留证",
                    value: "D",
                },
            ],
            coAdrOptions: [],
            branchId1Options: [],
            coAdrObj: {
                coAdrProvCityCode: "",
                coAdr: "",
            },
            rules: {
                defaultMobile: [
                    { required: true, message: "请输入手机号！", trigger: "blur" },
                    { validator: defaultMobileValidate, trigger: "blur" }
                ],
                managerNo: [
                    { validator: managerNoValidate, trigger: "blur" }
                ],
                apntmChannel: [
                    { required: true, message: "请选择开户渠道！", trigger: "change" },
                ],
                clientName: [
                    { required: true, message: "请输入客户名称！", trigger: "blur" },
                ],
                acctTp: [
                    { required: true, message: "请选择账户性质类型！", trigger: "change" },
                ],
                officeAdr: [
                    { required: true, message: "请输入办公地址！", trigger: "blur" },
                ],
                officeTel: [
                    { validator: officeTelValidate, trigger: "blur" }
                ],
                zipCode: [
                    { validator: zipCodeValidate, trigger: "blur" }
                ],
                crCtfExpDt: [
                    { required: true, message: "请选择统一社会信用代码到期日！", trigger: "blur" },
                ],
                rgstCptl: [
                    { required: true, message: "请输入注册资金！", trigger: "blur" },
                    { validator: rgstCptlValidate, trigger: "blur" }
                ],
                bussScope1: [
                    { required: true, message: "请输入经营范围！", trigger: "blur" },
                ],
                aprvAdr: [
                    { required: true, message: "请输入注册地址！", trigger: "blur" },
                ],
                openAcctLcnsId: [
                    { required: true, message: "请输入基本存款账户开户许可证核准号！", trigger: "blur" },
                    { validator: openAcctLcnsIdValidate, trigger: "blur" }
                ],
                openAcctLcnsIdNR: [
                    { required: false, message: "请输入基本存款账户开户许可证核准号！", trigger: "blur" },
                    { validator: openAcctLcnsIdValidate, trigger: "blur" }
                ],
                openBrName: [
                    { required: true, message: "请输入基本户开户银行！", trigger: "blur" },
                ],
                bscBnkAcctNoNR: [
                    { required: false, message: "", trigger: "blur" },
                    { validator: bscBnkAcctNoValidate, trigger: "blur" }
                ],
                bscBnkAcctNo: [
                    { required: true, message: "请输入基本户账号！", trigger: "blur" },
                    { validator: bscBnkAcctNoValidate, trigger: "blur" }
                ],
                prsnIdCardId: [
                    { validator: prsnIdCardIdValidate, trigger: "blur" }
                ],
                fixedTel: [
                    { validator: fixedTelValidate, trigger: "blur" }
                ],
                mblNo: [
                    { validator: mblNoValidate, trigger: "blur" }
                ],
                corpName: [
                    { required: true, message: "请输入法定代表人姓名！", trigger: "blur" },
                ],
                lglRprsntCtfType: [
                    { required: true, message: "请选择法定代表人证件种类！", trigger: "change" },
                ],
                lglRprsntCtfNo: [
                    { required: true, message: "请输入法定代表人身份证件号码！", trigger: "blur" },
                    { validator: lglRprsntCtfNoValidate, trigger: "blur" }
                ],
                corpCtfExpDate: [
                    { required: true, message: "请选择法定代表人证件到期日！", trigger: "blur" },
                ],
                fixedTel2: [
                    { validator: fixedTel2Validate, trigger: "blur" }
                ],
                gntCorpTelephone: [
                    { required: true, message: "请输入法定代表人手机！", trigger: "blur" },
                    { validator: gntCorpTelephoneValidate, trigger: "blur" }
                ],
                agentCtfId: [
                    { validator: agentCtfIdValidate, trigger: "blur" }
                ],
                agntTelNo: [
                    { validator: agntTelNoValidate, trigger: "blur" }
                ],
                agntPhoneNo: [
                    { validator: agntPhoneNoValidate, trigger: "blur" }
                ],
                shrhlrCtfType: [
                    { required: true, message: "请选择证件类型！", trigger: "change" },
                ],
                shrhlrCtfId: [
                    { required: true, message: "请输入证件号码！", trigger: "blur" },
                    { validator: shrhlrCtfIdValidate, trigger: "blur" }
                ],
                ctfVldDate: [
                    { required: true, message: "请选择证件有效期！", trigger: "change" },
                ],
                openAcctLcnsId1: [
                    { validator: openAcctLcnsId1Validate, trigger: "blur" }
                ],
                orgInstId: [
                    { validator: orgInstIdValidate, trigger: "blur" }
                ],
                corpCtfId: [
                    { validator: corpCtfIdValidate, trigger: "blur" }
                ],
                payerCtfID: [
                    { validator: payerCtfIDValidate, trigger: "blur" }
                ],
                contactTel: [
                    { validator: contactTelValidate, trigger: "blur" }
                ],
                ctcEmail: [
                    { validator: ctcEmailValidate, trigger: "blur" }
                ],
                payerCtfID1: [
                    { validator: payerCtfID1Validate, trigger: "blur" }
                ],
                contactTel2: [
                    { validator: contactTel2Validate, trigger: "blur" }
                ],
                ctcEmail1: [
                    { validator: ctcEmail1Validate, trigger: "blur" }
                ],
                unfSocCrdtNo: [
                    { required: true, message: "请输入统一社会信用代码号码！", trigger: "blur" },
                    { validator: unfSocCrdtNoValidate, trigger: "blur" }
                ]
            },
        }
    }
}